import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, heroText, mobileHero, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100VH"
          w="100%"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          column
          justifyBetween
          alignCenter
        >
          <Header />
          <CFView textCenter column center w="100%">
            <CFView column center>
              <CFImage
                w="85%"
                src={mobileHeroText}
                alt="Mumu Kitchen hero text"
                zIndex={98}
              />
            </CFView>
            <CFView mt="25px" mb="35px">
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="700px"
          w="100%"
          image={`url(${hero}) top/ cover no-repeat`}
          zIndex={90}
          column
          justifyBetween
          alignStart
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
        >
          <Header />
          <CFView column justifyCenter alignStart ml="80px" h="640px">
            <CFView column justifyCenter alignStart>
              <CFImage
                h="300px"
                src={heroText}
                alt="Mumu Kitchen hero text"
                zIndex={98}
              />
            </CFView>
            <CFView mv="30px">
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
